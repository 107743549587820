import { useSelector } from "react-redux";
import { selectImages } from "../../redux/reducers/images";
import { ArrowDownCircleIcon, PhotoIcon } from "@heroicons/react/24/outline";

export const Images = () => {
	const capturedImages = useSelector(selectImages);
	const arr = new Array(12 - capturedImages.length).fill(1);

	return (
		<div
			className="flex flex-row overflow-x-scroll gap-4 mt-2"
			id={"image-scroll"}
		>
			{capturedImages.map((faceImage, index) => (
				<img
					src={faceImage.toDataURL()}
					alt={`Face ${index}`}
					className="w-1/5 h-auto"
				/>
			))}
			{arr.map((_, index) => (
				<span
					type="button"
					className="relative block w-1/5 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				>
					<PhotoIcon className="h-6 w-6 mx-auto" />
					<span className="mt-2 w-[40px] block text-xs font-semibold text-gray-900">
						{index + 1 + capturedImages.length} / 12
					</span>
				</span>
			))}
		</div>
	);
};
