import React from "react";

const CompanyLogo = () => {
	return (
		<div className="mb-6 md:mb-0 flex flex-col items-center justify-center">
			<a href="" className="flex items-center">
				<img
					src="/passportLogo.png"
					className="mr-2 h-8 bg-white rounded"
					alt="ECS Logo"
				/>
				<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
					passportphotos.pro
				</span>
			</a>
		</div>
	);
};

export default CompanyLogo;
