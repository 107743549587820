import * as faceapi from "face-api.js";

import { useState, useEffect, useRef } from "react";
import { getWebsiteDomain } from "../../auth_config";
import { useDispatch, useSelector } from "react-redux";
import { selectImages, selectWebcamStream } from "../../redux/reducers/images";
import { startWebcam } from "../../redux/actions/startWebcam";
import { startCapture } from "../../redux/actions/startCapture";
import { zipImages } from "../../redux/actions/zipImages";

export const Webcam = ({ videoRef }) => {
	const [webcamLoaded, setWebcamLoaded] = useState(false);
	const [modelsLoaded, setModelsLoaded] = useState(false);

	const canvasRef = useRef(null);
	const dispatch = useDispatch();
	const webcamStream = useSelector(selectWebcamStream);
	const capturedImages = useSelector(selectImages);

	useEffect(() => {
		const video = videoRef.current;
		const canvas = canvasRef.current;

		if (video && canvas) {
			video.width = video.offsetWidth;
			video.height = video.offsetHeight;
			canvas.width = video.offsetWidth;
			canvas.height = video.offsetHeight;
		}
	}, []);

	useEffect(() => {
		const video = videoRef.current;

		if (webcamStream && video) {
			video.srcObject = webcamStream;
			video.play();
			setWebcamLoaded(true);
		}
	}, [webcamStream]);

	useEffect(() => {
		const loadModels = async () => {
			const MODEL_URL = getWebsiteDomain() + "/models";

			Promise.all([
				faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
				faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
				faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
				faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
			]).then(setModelsLoaded(true));
		};
		loadModels();
	}, []);

	return (
		modelsLoaded && (
			<div className="flex flex-col justify mt-4">
				{}
				<video
					ref={videoRef}
					style={{ display: webcamLoaded ? "block" : "none" }}
					className={`w-full h-[200px] mb-2`}
					playsInline
				></video>
				{/* {!webcamLoaded && (
					<button
						onClick={() => dispatch(startWebcam(videoRef))}
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					>
						Start Webcam
					</button>
				)} */}
				{webcamLoaded && capturedImages.length < 12 && (
					<button
						onClick={() => dispatch(startCapture({ videoRef, faceapi }))}
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					>
						Start Capture
					</button>
				)}
				{capturedImages.length === 12 && (
					<button
						onClick={() => dispatch(zipImages())}
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					>
						Download Images
					</button>
				)}
			</div>
		)
	);
};
