import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from "react-router-dom";
import { LandingPage } from "./components/Landing";
import { ApolloProvider } from "@apollo/client";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { client } from "./api/client";
import { EmailPasswordHeadersOverride, SuperTokensConfig } from "./auth_config";

SuperTokens.init(SuperTokensConfig);

function App() {
	return (
		<SuperTokensWrapper>
			<ThirdpartyEmailPasswordComponentsOverrideProvider
				components={{
					EmailPasswordSignInHeader_Override: EmailPasswordHeadersOverride,
					EmailPasswordSignUpHeader_Override: EmailPasswordHeadersOverride,
				}}
			>
				<ApolloProvider client={client}>
					<Router>
						<div className="flex flex-col justify-center">
							<Routes>
								<Route path="*" element={<Navigate to="/" />} />
								<Route
									path="/"
									element={
										<>
											<LandingPage />
										</>
									}
								/>
							</Routes>
						</div>
					</Router>
				</ApolloProvider>
			</ThirdpartyEmailPasswordComponentsOverrideProvider>
		</SuperTokensWrapper>
	);
}

export default App;
