import { addImage } from "../reducers/images";

export const startCapture =
	({ videoRef, faceapi }) =>
	async (dispatch) => {
		try {
			const video = videoRef.current;
			if (video) {
				let i = 0;
				while (i < 12) {
					const detections = await faceapi
						.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
						.withFaceLandmarks()
						.withFaceDescriptor();

					if (detections) {
						i++;
						try {
							const paddingX = 40;
							const paddingY = 80;

							const faceBox = detections.detection.box;
							const x = Math.max(0, faceBox.x - paddingX);
							const y = Math.max(0, faceBox.y - paddingY);
							const width = faceBox.width + paddingX * 2;
							const height = faceBox.height + paddingY * 2;

							const scale = 2;
							const aspectRatio = width / height;
							const canvasWidth = Math.round(height * aspectRatio * scale);
							const canvasHeight = height * scale;

							const canvas = document.createElement("canvas");
							canvas.width = canvasWidth;
							canvas.height = canvasHeight;
							const context = canvas.getContext("2d");
							context.drawImage(
								video,
								x,
								y,
								width,
								height,
								0,
								0,
								canvasWidth,
								canvasHeight
							);

							dispatch(addImage(canvas));

							const scrollContainer = document.getElementById("image-scroll");
							scrollContainer.scrollLeft = scrollContainer.scrollWidth;
						} catch (err) {
							console.error(err);
						}
					}
					await new Promise((resolve) => setTimeout(resolve, 300));
				}
			}
		} catch (error) {
			console.log({ error });
		}
	};
