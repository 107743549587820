import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	webcamStream: null,
	images: [],
};

export const imagesSlice = createSlice({
	name: "images",
	initialState,
	reducers: {
		setWebcamStream: (state, action) => {
			state.webcamStream = action.payload;
		},
		setImages: (state, action) => {
			state.images = action.payload;
		},
		addImage: (state, action) => {
			state.images.push(action.payload);
		},
	},
});

export const { setWebcamStream, setImages, addImage } = imagesSlice.actions;

export const selectWebcamStream = (state) => state.images.webcamStream;
export const selectImages = (state) => state.images.images;

export default imagesSlice.reducer;
