export const Pricing = () => {
	return (
		<div id="pricing" className="bg-gray-50">
			<div className="mx-auto max-w-7xl pt-16">
				<div className="mx-auto max-w-2xl sm:text-center">
					<h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						Simple Pricing
					</h2>
					<hr className="mt-4 border-1 border-gray-300" />
					<p className="text-center mt-6 text-lg leading-8 text-gray-600">
						No Subscription | No Login | No Hassle
					</p>
				</div>
				<div className="mt-8 bg-white items-center mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
					<div className="p-8 sm:p-10 lg:flex-auto">
						<h3 className="text-2xl font-bold tracking-tight text-gray-900">
							One Time Payment
						</h3>
						<p className="mt-6 text-base leading-7 text-gray-600">
							Ideal for users who need quality passport photos without the extra
							frills.
						</p>
						<div className="mt-10 flex items-center gap-x-4 grid grid-cols-4">
							<img src="/passport0.png" />
							<img src="/passport1.png" />
							<img src="/passport2.png" />
							<img src="/passport3.png" />
						</div>
					</div>
					<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 h-full">
						<div className="rounded-2xl h-full bg-gray-50 m-8 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
							<div className="mx-auto max-w-xs px-8">
								<p className="text-base font-semibold text-gray-600">
									Pay once, own it forever
								</p>
								<p className="mt-6 flex items-baseline justify-center gap-x-2">
									<span className="text-5xl font-bold tracking-tight text-gray-900">
										$3.99
									</span>
									<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
										USD
									</span>
								</p>
								<p className="mt-3 text-sm text-gray-600">
									Per 4 passport photos
								</p>
								<div className="mt-6">
									<p className="text-sm font-semibold text-blue-400 hover:text-blue-700">
										For refunds, please contact <br />
										<a href="mailto:info@passportphotos.com">
											info@passportphotos.pro
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pb-4 bg-gray-50">
				<div className="max-w-xl mx-auto my-12 bg-white p-6 rounded-lg ring-1 ring-inset ring-gray-900/5 ">
					<h2 className="text-2xl font-bold mb-4 text-center">
						Passport Service Comparison
					</h2>
					<div className="space-y-4">
						<div className="flex items-center bg-gray-100 p-4 rounded">
							<img
								src="/passportmed.png"
								alt="Our Service"
								className="w-10 h-10 rounded-full mr-4"
							/>
							<div className="min-w-[200px]">
								<h3 className="font-bold">PassportPhotos.pro</h3>
								<p>
									<strong>Pricing:</strong>
									<span className="text-green-700"> $3.99</span>
								</p>
								<p>
									<strong>Effort:</strong> Instant
								</p>
							</div>
							<span className="text-green-500 text-5xl w-full text-right mr-16">
								👍
							</span>
						</div>

						<div className="flex items-center bg-gray-200 p-4 rounded">
							<img
								src="https://images.ctfassets.net/nu3qzhcv2o1c/3CyvJzxfGmux150UrFIN9e/fb472412561df30a688a0b897c998cc0/cvs-logo.svg"
								alt="CVS"
								className="w-10 h-10 rounded-full mr-4"
							/>
							<div className="min-w-[200px]">
								<h3 className="font-bold">CVS</h3>
								<p>
									<strong>Pricing:</strong>{" "}
									<span className="text-red-700"> $16.99</span>
								</p>
								<p>
									<strong>Effort:</strong> Get Ready + Travel
								</p>
							</div>
							<span className="text-green-500 text-5xl w-full text-right mr-16">
								👎
							</span>
						</div>

						<div className="flex items-center bg-gray-100 p-4 rounded">
							<img
								src="https://play-lh.googleusercontent.com/gK5U3QZnDLSysaBFcsiCOyknNRZpG669NmreEUvGqlamTQwafKoVTUVUoZ8rqdBzlMsw"
								alt="Walmart"
								className="w-10 h-10 mr-4"
							/>
							<div className="min-w-[200px]">
								<h3 className="font-bold">Walgreens</h3>
								<p>
									<strong>Pricing:</strong>
									<span className="text-red-700"> $16.99</span>
								</p>
								<p>
									<strong>Effort:</strong> Get Ready + Travel
								</p>
							</div>
							<span className="text-green-500 text-5xl w-full text-right mr-16">
								👎
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
