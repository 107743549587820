import { useSearchParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { Webcam } from "./webcam";
import { useEffect, useRef } from "react"; // <-- Import useEffect and useRef
import { Choose } from "./Choose";
import { Images } from "./Images";

export const Passport = () => {
	const [searchParams] = useSearchParams();
	let passport_set = searchParams.get("passport_set");
	const mainRef = useRef(null);
	const videoRef = useRef(null);

	useEffect(() => {
		if (passport_set && mainRef.current) {
			mainRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [passport_set]);

	return (
		<div
			className="bg-white mx-auto max-w-7xl mt-12 relative"
			id="main"
			ref={mainRef}
		>
			<div className="absolute inset-0 flex justify-center items-center z-100">
				<span className="text-4xl font-bold text-gray-800">Coming Soon...</span>
			</div>
			<div className="mx-auto max-w-2xl sm:text-center mb-8">
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
					Get your photos without leaving home
				</h2>
				<hr
					className="mt-4
				border-1 border-gray-300"
				/>
			</div>

			<div className="opacity-50 p-4 mx-8 border shadow-md mb-16">
				<Breadcrumbs />
				<Choose videoRef={videoRef} />
				<Webcam videoRef={videoRef} />
				<Images />
			</div>
		</div>
	);
};
