import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { getApiDomain } from "../auth_config";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";

export const link = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
	new GraphQLWsLink(
		createClient({
			url:
				getApiDomain().replace("https", "wss").replace("http", "ws") +
				"/graphql",
			shouldRetry: () => true,
			retryWait: async function waitForServerHealthyBeforeRetry() {
				console.log("reconnecting soon");
				await new Promise((resolve) =>
					setTimeout(resolve, 500 + Math.random() * 1000)
				);
			},
		})
	),
	new HttpLink({
		uri: getApiDomain() + "/graphql",
	})
);

export const client = new ApolloClient({
	link,
	cache: new InMemoryCache(),
});
