export const LandingNavbar = ({ companyName = "PassportPhotos.pro" }) => {
	return (
		<nav
			className={`border-gray-600 w-full z-50 transition-colors duration-500 bg-white`}
		>
			<div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
				<a class="flex items-center">
					<img
						src={"/passportLogo.png"}
						height="60"
						width="60"
						alt="Company Logo"
					/>
					<span class=" self-center text-xl font-semibold whitespace-nowrap dark:text-white">
						{companyName}
					</span>
				</a>
			</div>
		</nav>
	);
};
