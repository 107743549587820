import JSZip from "jszip";

export const zipImages = () => async (dispatch, getState) => {
	try {
		const zip = new JSZip();
		const folder = zip.folder("images");

		let capturedImages = getState().images.images;

		capturedImages.forEach((image, index) => {
			folder.file(`image_${index}.png`, image.toDataURL().split(",")[1], {
				base64: true,
			});
		});
		zip.generateAsync({ type: "blob" }).then((content) => {
			const link = document.createElement("a");
			link.href = URL.createObjectURL(content);
			link.download = "images.zip";
			link.click();
		});
	} catch (error) {
		console.log({ error });
	}
};
