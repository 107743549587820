import { HeroSection } from "./HeroSection";
import { FeatureSection } from "./FeatureSection";
import { Footer } from "../Footer";
import { LandingNavbar } from "./LandingNavbar";
import { Pricing } from "./Pricing";
import { Cta } from "./Cta";
import { Passport } from "../Passport";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";

export const LandingPage = () => {
	const [searchParams] = useSearchParams();
	let passport_set = searchParams.get("passport_set");

	return (
		<div className="">
			<LandingNavbar />
			<HeroSection />
			<Pricing />
			<Passport />
			{/* <FeatureSection /> */}
			{/* <Cta /> */}
			<Footer />
		</div>
	);
};
