import ThirdPartyEmailPassword, { Google, Github, Apple } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";

export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 3001;
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    console.log(process.env.REACT_APP_API_URL);
    console.log({apiUrl});
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}


export const SuperTokensConfig = {
    appInfo: {
        appName: "pre.dev",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        ThirdPartyEmailPassword.init({
            signInAndUpFeature: {
                providers: [Github.init(), Google.init(), Apple.init()],
                defaultToSignUp: true
            },
            getRedirectionURL: async (context) => {
                if (context.action === "SUCCESS") {
                    if (context.redirectToPath !== undefined) {
                        // we are navigating back to where the user was before they authenticated
                        return context.redirectToPath;
                    }
                    return "/home";
                }
                return undefined;
            }
        }),
        EmailVerification.init({
            mode: "REQUIRED", // or "OPTIONAL",
        }),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};


export const PreBuiltUIList = [
    EmailVerificationPreBuiltUI,
    // ThirdPartyEmailPasswordPreBuiltUI
];

export const EmailPasswordHeadersOverride: React.FC<any> = ({ DefaultComponent, ...props }) => {
    return (
      <div>
        <img
          style={{ height: "50px" }}
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
        />
        <h1 style={{ margin: 0 }}>pre.dev</h1>

        <DefaultComponent {...props} />
      </div>
    );
  };
  