import {
	ArrowUpTrayIcon,
	CameraIcon,
	PhotoIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { startCapture } from "../../redux/actions/startCapture";

export const Choose = ({ videoRef }) => {
	const dispatch = useDispatch();
	return (
		<div className="grid grid-cols-2 gap-x-4 mt-4" id="main">
			<button
				disabled={true}
				type="button"
				className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
			>
				<ArrowUpTrayIcon className="h-6 w-6 mx-auto" />
				<span className="mt-2 block text-sm font-semibold text-gray-900">
					Upload Selfies
				</span>
			</button>

			<button
				disabled={true}
				type="button"
				onClick={() => dispatch(startCapture(videoRef))}
				className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
			>
				<CameraIcon className="h-6 w-6 mx-auto" />
				<span className="mt-2 block text-sm font-semibold text-gray-900">
					Take Selfies via Webcam
				</span>
			</button>
		</div>
	);
};
