import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export const HeroSection = () => {
	const navigate = useNavigate();

	return (
		<div className="relative bg-white">
			<div className="mx-auto max-w-7xl flex lg:flex-row flex-col items-center mb-4">
				<div className="lg:w-1/2 w-full px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7  lg:pb-56 xl:col-span-6">
					<h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">
						Turn your{" "}
						<span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-blue-500 to-purple-500">
							selfies
						</span>{" "}
						into{" "}
						<span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-pink-500 to-purple-500">
							passport photos.
						</span>
					</h1>
					<p className="mt-6 text-lg leading-8 text-gray-600 pr-3">
						Our cutting-edge AI technology effortlessly transforms your selfies
						into professional-quality passport photos. Save time by getting your
						passport photo done in the comfort of your living room.
					</p>
					<div className="mt-10 flex items-center gap-x-6">
						<a
							href="#main"
							className="flex items-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
						>
							Get started <ArrowRightIcon className="h-4 w-4 ml-1" />
						</a>
					</div>
				</div>
				<div className="lg:w-1/2 w-full flex justify-center items-center">
					<img
						className="h-[500px] bg-gray-50 object-cover lg:inset-0 lg:aspect-auto"
						src="/passport2.jpg"
						alt="Selfie to Passport Photo AI App"
					/>
				</div>
			</div>
		</div>
	);
};
